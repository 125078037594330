export const genderChoices = ["Female", "Male", "Other"]

export const sites = [
  {
    label: "BOH (Bohol)",
    value: "taskus_boh",
  },
  {
    label: "CHR (Anonas)",
    value: "taskus_chr",
  },
  {
    label: "FE (Bulacan)",
    value: "taskus_fex",
  },
  {
    label: "HTW (Pampanga)",
    value: "taskus_htw",
  },
  {
    label: "LBL (BGC)",
    value: "taskus_lbl",
  },
  {
    label: "LH (La Union)",
    value: "taskus_lh",
  },
  {
    label: "LL (Batangas)",
    value: "taskus_lzl",
  },
  {
    label: "LN (Cavite)",
    value: "taskus_lzn",
  },
  {
    label: "LWT (Antipolo)",
    value: "taskus_lwt",
  },
]

export const relationshipOptions = [
  {
    value: "parent",
    label: "Parent",
  },
  {
    value: "spouse",
    label: "Spouse",
  },
  {
    value: "child",
    label: "Child",
  },
  {
    value: "sibling",
    label: "Sibling",
  },
  {
    value: "others",
    label: "Others",
  },
]
