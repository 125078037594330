import {
  relationshipOptions,
  genderChoices,
  sites,
} from "../../Enrollment/utils/formData"

export const profileFormFields = (
  values,
  setFieldValue,
  errors,
  submitCount
) => {
  return [
    {
      type: "password",
      name: "oldPassword",
      label: "Current Password",
      placeholder: "********",
      inclusions: ["update-password"],
      isFollowUpQuestion: false,
      isRequired: true,
    },
    {
      type: "password",
      name: "newPassword",
      label: "New Password",
      placeholder: "********",
      inclusions: ["update-password"],
      isFollowUpQuestion: false,
      isRequired: true,
    },
    {
      type: "password",
      name: "confirmPassword",
      label: "Confirm Password",
      placeholder: "********",
      inclusions: ["update-password"],
      isFollowUpQuestion: false,
      isRequired: true,
    },
    {
      type: "text",
      name: "firstName",
      label: "First Name",
      placeholder: "Juan",
      inclusions: ["edit"],
      isFollowUpQuestion: false,
      isRequired: true,
    },
    {
      type: "text",
      name: "lastName",
      label: "Last Name",
      placeholder: "Dela Cruz",
      inclusions: ["edit"],
      isFollowUpQuestion: false,
      isRequired: true,
    },
    {
      type: "text",
      name: "mobileNumber",
      label: "Mobile Number",
      placeholder: "09123456789",
      inclusions: ["edit"],
      isFollowUpQuestion: false,
      isRequired: true,
    },
    {
      type: "text",
      name: "email",
      label: "Email",
      placeholder: "jdelacruz@gmail.com",
      inclusions: ["edit"],
      disabled: true,
      isFollowUpQuestion: false,
      isRequired: true,
    },
    {
      type: "text",
      name: "personalEmail",
      label: "Personal Email",
      placeholder: "jdelacruz@gmail.com",
      inclusions: ["edit"],
      // disabled: true,
      isFollowUpQuestion: false,
      isRequired: true,
    },
    {
      type: "date",
      name: "birthday",
      label: "Birthday",
      inclusions: ["edit"],
      values: values?.birthday,
      isFollowUpQuestion: false,
      isRequired: true,
      errors: errors,
      submitCount: submitCount,
    },
    {
      type: "radio",
      name: "gender",
      title: "Gender",
      value: values?.gender,
      options: genderChoices,
      inclusions: ["edit"],
      isFollowUpQuestion: false,
      isRequired: true,
    },
    {
      type: "address",
      fieldNames: {
        // addressType: `address.addressType`,
        streetAddress: `address.streetAddress`,
        city: `address.city`,
        province: `address.province`,
      },
      values: values?.address,
      setFieldValue: setFieldValue,
      inclusions: ["add-address", "edit-address"],
      isNationwide: true,
      isRequired: true,
    },
  ]
}

export const emergencyFormFields = (values, errors, submitCount) => {
  return [
    {
      type: "text",
      name: "emergencyContact.name",
      label: "Emergency Contact Name",
      placeholder: "09123456789",
      inclusions: ["edit"],
      isFollowUpQuestion: false,
      isRequired: true,
    },
    {
      type: "text",
      name: "emergencyContact.mobileNumber",
      label: "Emergency Contact Mobile Number",
      placeholder: "09123456789",
      inclusions: ["edit"],
      isFollowUpQuestion: false,
      isRequired: true,
    },
    {
      type: "select",
      name: "emergencyContact.relationship",
      label: "Relationship to employee",
      value: values?.emergencyContact?.relationship,
      options: relationshipOptions,
      inclusions: ["edit"],
      isFollowUpQuestion: false,
      isRequired: true,
      errors: errors,
      submitCount: submitCount,
    },
  ]
}

export const employeeFormFields = (values, errors, submitCount) => {
  return [
    {
      type: "text",
      name: "employeeNumber",
      label: "Employee Number",
      placeholder: "1234567",
      inclusions: ["edit"],
      isFollowUpQuestion: false,
      isRequired: true,
    },
    {
      type: "select",
      name: "site",
      label: "Site",
      value: values?.site,
      options: sites,
      inclusions: ["edit"],
      isFollowUpQuestion: false,
      isRequired: true,
      errors: errors,
      submitCount: submitCount,
    },
    {
      type: "text",
      name: "department",
      label: "Account/Department",
      placeholder: "HR",
      inclusions: ["edit"],
      isFollowUpQuestion: false,
      isRequired: true,
    },
  ]
}
