import React, { Fragment } from "react"
import {
  profileFormFields,
  emergencyFormFields,
  employeeFormFields,
} from "./utils/profileFormFields"
import { generateFormField } from "elements/Form/services/form"
import Section from "elements/Section"

const EditForms = ({ setFieldValue, module, values, errors, submitCount }) => {
  let personalDetailsFormFields = profileFormFields(
    values,
    setFieldValue,
    errors,
    submitCount
  )
  let emergencyContactFormFields = emergencyFormFields(
    values,
    errors,
    submitCount
  )
  let employeeDetailsFormFields = employeeFormFields(
    values,
    errors,
    submitCount
  )

  return (
    <Fragment>
      {module === "edit" ? (
        <Fragment>
          <Section title="Personal Information">
            {personalDetailsFormFields
              .filter((formField) => {
                return formField.inclusions.includes(module)
              })
              .map((formField) => {
                if (!formField?.referenceAnswer) {
                  return generateFormField({
                    formField,
                    values,
                    formFields: personalDetailsFormFields,
                  })
                }
              })}
          </Section>
          <Section title="Employee Information">
            {employeeDetailsFormFields
              .filter((formField) => {
                return formField.inclusions.includes(module)
              })
              .map((formField) => {
                if (!formField?.referenceAnswer) {
                  return generateFormField({
                    formField,
                    values,
                    formFields: employeeDetailsFormFields,
                  })
                }
              })}
          </Section>
          <Section title="Emergency Contact Information" id="emergency-contact">
            {emergencyContactFormFields
              .filter((formField) => {
                return formField.inclusions.includes(module)
              })
              .map((formField) => {
                if (!formField?.referenceAnswer) {
                  return generateFormField({
                    formField,
                    values,
                    formFields: personalDetailsFormFields,
                  })
                }
              })}
          </Section>
        </Fragment>
      ) : (
        <Fragment>
          {personalDetailsFormFields
            .filter((formField) => {
              return formField.inclusions.includes(module)
            })
            .map((formField) => {
              if (!formField?.referenceAnswer) {
                return generateFormField({
                  formField,
                  values,
                  formFields: personalDetailsFormFields,
                })
              }
            })}
        </Fragment>
      )}
    </Fragment>
  )

  // return formFields
  //   .filter((formField) => {
  //     return formField.inclusions.includes(module)
  //   })
  //   .map((formField) => {
  //     if (!formField?.referenceAnswer) {
  //       return generateFormField({
  //         formField,
  //         values,
  //         formFields: formFields,
  //       })
  //     }
  //   })
}

export default EditForms
